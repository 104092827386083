import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { Box } from '@primer/react';
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Caption = makeShortcode("Caption");
const DoDontContainer = makeShortcode("DoDontContainer");
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h2>{`Overview`}</h2>
    <p>{`All dates and times displayed should use the relative time component, which is able to format and localise dates into the users given locale, as well as display times relative to the current date, while also updating in real-time.`}</p>
    <p>{`Dates and times almost always appear `}<a parentName="p" {...{
        "href": "#relative-dates"
      }}>{`relative`}</a>{` to the current date, using short-hand formats to help users quickly determine how old something is or how soon something will be. For this, we use the relative time component which displays dates rounded to the nearest unit, for example, "a minute ago" or "20 days ago". For dates that are more than 1 month from the current date, it can be more useful to display the date-time, as these items are less "current" and more "historical".`}</p>
    <p>{`Some dates are especially sensitive and always should be displayed in a `}<a parentName="p" {...{
        "href": "#precise-dates"
      }}>{`precise`}</a>{` date-time format, such as the creation or expiration of certificates and keys. When a user needs to take action before a particular time (such as an expiry date) then the precise date time should be displayed. These should display the weekday, the date, the month, and the year (if the year is not the current year), for example, "Thursday, 26 August 2021" or "Saturday, 31 December 2022".`}</p>
    <p>{`Some dates and times represent the start or (expected) end of an actively running task, for these the `}<a parentName="p" {...{
        "href": "#elapsed-or-remaining-dates"
      }}>{`elapsed or remaining`}</a>{` time should be displayed, as this information is more useful than when a task started. This format should display all units that are greater than zero, for example, "2 minutes, 24 seconds" or "8 hours, 15 seconds". For ephemeral tasks where timing needs to be quickly consumed, or where a lot of times may be displayed together, a compact time notation can be used, for example, "2m 24s" or "8h 15s".`}</p>
    <h2>{`Usage`}</h2>
    <p>{`This component does not include any styling. It formats a timestamp as a localized string or as relative text that auto-updates in the user's browser.`}</p>
    <h2>{`Options`}</h2>
    <h3>{`Relative dates`}</h3>
    <h4>{`Past dates`}</h4>
    <p>{`For past dates, the relative time component outputs different formats depending on the time difference. Within a month's time, the output shows a relative time`}<sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup>{` in the format of `}<inlineCode parentName="p">{`X seconds/minutes/hours/days ago`}</inlineCode>{`. If the time difference is less than 1 minute, it shows `}<inlineCode parentName="p">{`just now`}</inlineCode>{`. If the date is in past months, the date time`}<sup parentName="p" {...{
        "id": "fnref-2"
      }}><a parentName="sup" {...{
          "href": "#fn-2",
          "className": "footnote-ref"
        }}>{`2`}</a></sup>{` format `}<inlineCode parentName="p">{`on D MMM`}</inlineCode>{` is used. If the date is in the past years, the output uses the long format`}<sup parentName="p" {...{
        "id": "fnref-3"
      }}><a parentName="sup" {...{
          "href": "#fn-3",
          "className": "footnote-ref"
        }}>{`3`}</a></sup>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Time difference`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Format`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Examples`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`< 1 minute`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`just now`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`just now`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`< 1 month`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`X seconds/minutes/hours/days ago`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`a minute ago`}</inlineCode>{`, `}<inlineCode parentName="td">{`5 minutes ago`}</inlineCode>{`, `}<inlineCode parentName="td">{`an hour ago`}</inlineCode>{`, `}<inlineCode parentName="td">{`3 hours ago`}</inlineCode>{`, `}<inlineCode parentName="td">{`yesterday`}</inlineCode>{`, `}<inlineCode parentName="td">{`20 days ago`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`> 1 month`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`on MMM D`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`on Nov 18`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Past year`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`localised`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`on Nov 18, 2021`}</inlineCode>{` (US English), `}<inlineCode parentName="td">{`on 18 Nov, 2021`}</inlineCode>{` (British English), `}<inlineCode parentName="td">{`on 2021 Nov 18`}</inlineCode>{` (Korean)`}</td>
        </tr>
      </tbody>
    </table>
    <p>
  <img width="960" alt="Relative time examples" src="https://github.com/primer/design/assets/912236/d1e21038-edb6-4897-8ae7-778fd377a3ae" />
    </p>
    <h4>{`Future dates`}</h4>
    <p>{`Future dates also use different formats depending on the time difference. If the future date is due to happen within the a month's time, the relative time outputs the format of `}<inlineCode parentName="p">{`in X seconds/minutes/hours/days`}</inlineCode>{`. If the time difference is less than 1 minute, it shows `}<inlineCode parentName="p">{`just now`}</inlineCode>{`. If the date is in the following months, the format `}<inlineCode parentName="p">{`on D MMM`}</inlineCode>{` is used. If the date is in the next year or further, the output uses the long format`}<sup parentName="p" {...{
        "id": "fnref-3"
      }}><a parentName="sup" {...{
          "href": "#fn-3",
          "className": "footnote-ref"
        }}>{`3`}</a></sup>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Time difference`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Format`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Examples`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`< 1 minute`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`just now`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`just now`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`< 1 month`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`in X seconds / minutes / hours / days`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`in a minute`}</inlineCode>{`, `}<inlineCode parentName="td">{`in 2 minutes`}</inlineCode>{`, `}<inlineCode parentName="td">{`in an hour`}</inlineCode>{`, `}<inlineCode parentName="td">{`in 7 hours`}</inlineCode>{`, `}<inlineCode parentName="td">{`tomorrow`}</inlineCode>{`, `}<inlineCode parentName="td">{`in 14 days`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`> 1 month`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`on MMM D`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`on Nov 18`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Next year`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`localised`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`on Aug 26, 2025`}</inlineCode>{` (US English), `}<inlineCode parentName="td">{`on 26 Aug, 2025`}</inlineCode>{` (British English), `}<inlineCode parentName="td">{`on 2025 Aug 26`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h4>{`Standalone`}</h4>
    <p>{`The relative dates can be used within a sentence, following the action that it's relative to, or as an independent elements when the content and the surrounding elements make it clear what the time is related to.`}</p>
    <Box display="grid" gridTemplateColumns={['1fr', null, null, null, '1fr 1fr']} gridGap={5} mdxType="Box">
  <div>
    <img alt="" src="https://github.com/primer/design/assets/912236/2f59aa2a-c8b0-4345-896c-a7feb74615c2" />
    <Caption mdxType="Caption">Use standalone relative dates on items where its title references the action.</Caption>
  </div>
  <div>
    <img alt="" src="https://user-images.githubusercontent.com/912236/208956687-503e9f34-2e95-46d1-9333-cd9cc7238ed3.png" />
    <Caption mdxType="Caption">Use standalone relative dates a timeline item where the section title references the action.</Caption>
  </div>
    </Box>
    <h4>{`Links`}</h4>
    <p>{`The relative dates are used as plain text in most cases. However, they can be used to point to a list of historical changes, a timeline, or as an anchor link for nested information in a view. For example, a date could be used to link to a comment in a conversation thread.`}</p>
    <img width="960" alt="A relative time as a link to a comment in a conversation." src="https://user-images.githubusercontent.com/912236/208957419-46174cce-00c1-4d18-8551-62c7a89c8148.png" />
    <h4>{`Responsive design and micro format`}</h4>
    <p>{`Relative dates must remain readable and accessible in different screen sizes. We encourage to use long date formats (e.g. `}<inlineCode parentName="p">{`2 months ago`}</inlineCode>{`) instead of the `}<inlineCode parentName="p">{`micro`}</inlineCode>{` format (e.g. `}<inlineCode parentName="p">{`2mo`}</inlineCode>{`) on mobile screens or narrow spaces, as it can be difficult for users to understand, it doesn't support browser translation, and causes issues with assistive technologies. For example, some screen readers, such as VoiceOver for mac, will read out `}<inlineCode parentName="p">{`1m`}</inlineCode>{` as `}<inlineCode parentName="p">{`1 meter`}</inlineCode>{`.`}</p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://user-images.githubusercontent.com/912236/209098128-37111f46-3b53-4201-82d3-be5721effe5a.png" role="presentation" />
    <Caption mdxType="Caption">Use readable, long date formats, and adapt the layout to the available space.</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://user-images.githubusercontent.com/912236/209098133-3452047c-4931-4460-bb3b-d4b9d9e51fb4.png" role="presentation" />
    <Caption mdxType="Caption">
      Don't use narrow date formats, specially if there's a prominent space available or the layout can be adapted to
      the screen size.
    </Caption>
  </Dont>
    </DoDontContainer>
    <h3>{`Precise dates`}</h3>
    <p>{`For dates that needs to represent a precise date and time which the user should be aware of, dates should be displayed in a precise format that includes the weekday, date, month and if appropriate the hours minutes and seconds. This will take the format of `}<inlineCode parentName="p">{`weekday, MMM D, YYYY, HH:MM:SS [AP]M`}</inlineCode>{`. The placement of these items may be different depending on locale.`}</p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://github.com/primer/design/assets/912236/151e9910-54a1-4f47-b6a3-f2ce084b9831" role="presentation" />
    <Caption mdxType="Caption">Use precise dates for future relevant dates.</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://user-images.githubusercontent.com/912236/209115031-e493457c-f62b-454a-ab52-4ec62b17ce11.png" role="presentation" />
    <Caption mdxType="Caption">Don't use relative dates for future specific dates.</Caption>
  </Dont>
    </DoDontContainer>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://user-images.githubusercontent.com/912236/209114717-1019f9f1-d0ae-4342-8ae3-849eda5dd835.png" role="presentation" />
    <Caption mdxType="Caption">Use precise dates when the user needs to know the exact time of past dates.</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://user-images.githubusercontent.com/912236/209114722-6eedf092-49b2-4c61-8657-1a462710ebee.png" role="presentation" />
    <Caption mdxType="Caption">Don't use relative dates for past specific dates.</Caption>
  </Dont>
    </DoDontContainer>
    <h3>{`Elapsed or remaining dates`}</h3>
    <p>{`For dates which represent the start or (expected) end of a currently running task or job, it is more useful to display the elapsed time since the task began, or remaining time until the task ends. This takes the long format of `}<inlineCode parentName="p">{`X years, X months, X days, X hours, X minutes, X seconds`}</inlineCode>{` or the compact format of `}<inlineCode parentName="p">{`Xy Xm Xd Xh Xm Xs`}</inlineCode>{`. Any `}<inlineCode parentName="p">{`0`}</inlineCode>{` values will not be displayed. For some tasks where the expected end time is further away and second precision is not needed, it may be useful to display less precise data, such as down-to-the-day, taking the format of `}<inlineCode parentName="p">{`Xy Xm Xd`}</inlineCode>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Precision`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Format`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Examples`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`second`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Xy Xm Xd Xh Xm Xs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`4s`}</inlineCode>{`, `}<inlineCode parentName="td">{`5m 32s`}</inlineCode>{`, `}<inlineCode parentName="td">{`1d 4h 32s`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`day`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Xy Xm Xd`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`2y 3m`}</inlineCode>{`, `}<inlineCode parentName="td">{`2y 8m 5d`}</inlineCode>{`, `}<inlineCode parentName="td">{`2m 2d`}</inlineCode>{`, `}<inlineCode parentName="td">{`1y 4d`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <div>
  <img alt="" src="https://user-images.githubusercontent.com/912236/209115404-1100b345-da19-4d17-b42b-b78c2619cec9.png" />
  <Caption mdxType="Caption">Use elapsed dates to represent the duration of a single or multiple running tasks.</Caption>
    </div>
    <h3>{`Word formatting`}</h3>
    <p>{`By default, the time will be prefixed with "on", but this can be customized.`}</p>
    <p>{`Weekday names and month names are fully written out by default, but they may be abbreviated.`}</p>
    <h3>{`Number formatting`}</h3>
    <p>{`Any part of the time can be rendered with or without a leading zero.`}</p>
    <h2>{`Accessibility and usability expectations`}</h2>
    <p>{`This component is intended to show a shorter, more user-friendly relative time (for instance, "3 hours ago", "20 Sept"), with the option for the user to still see the precise localized date/time.`}</p>
    <h3>{`Built-in accessibility features`}</h3>
    <p>{`The component renders text showing the chosen relative time (for instance, "1 week ago"). By default, it renders with a `}<inlineCode parentName="p">{`title`}</inlineCode>{` attribute containing the precise localized date/time. Doing this triggers a native browser tooltip when the component is hovered with the mouse. This means that by default, the component is not accessible to keyboard users, as there is no browser-native mechanism to show `}<inlineCode parentName="p">{`title`}</inlineCode>{` tooltips when navigating with a keyboard. See the `}<a parentName="p" {...{
        "href": "#implementation-requirements"
      }}>{`Implementation requirements`}</a>{` section for guidance on making it accessible.`}</p>
    <p>{`When using a screen reader, `}<inlineCode parentName="p">{`title`}</inlineCode>{` is usually announced as an additional description for specific elements, such as form controls, links, and dialogs. However, as the `}<inlineCode parentName="p">{`<relative-time>`}</inlineCode>{` component is a custom and semantically neutral element, screen readers only announce the visible text and ignore the `}<inlineCode parentName="p">{`title`}</inlineCode>{` attribute content, making the precise localized date/time not available to screen reader users by default.`}</p>
    <h3>{`Implementation requirements`}</h3>
    <p>{`Due to the current limitations of this component, one should consider alternative ways to expose the precise localized date/time to all users rather than only relying on the component's `}<inlineCode parentName="p">{`title`}</inlineCode>{` attribute tooltip alone.`}</p>
    <p>{`The specific way to achieve this will depend on the context of what you are building. For instance, you could use `}<inlineCode parentName="p">{`<relative-time>`}</inlineCode>{` on an initial listing or overview page, but then provide the full localized date/time in a subsequent details view, or in a dialog that shows more information for an individual item. Alternatively, provide a toggle or user setting that allows users to switch all instances of relative time to the full localized date/time display.`}</p>
    <p>{`General recommended approaches are:`}</p>
    <ul>
      <li parentName="ul">{`For interactive elements (such as using a link), use an accessible Primer Tooltip with the `}<inlineCode parentName="li">{`<relative-time>`}</inlineCode>{` component to display the full date/time and hide the `}<inlineCode parentName="li">{`title`}</inlineCode>{` attribute. See the `}<a parentName="li" {...{
          "href": "https://primer.style/react/storybook/?path=/story/components-relativetime-examples--link-with-tooltip&globals=colorScheme:light"
        }}>{`React 'Link with tooltip' story`}</a>{` and the `}<a parentName="li" {...{
          "href": "https://primer-lookbook.github.com/view-components/lookbook/inspect/primer/beta/relative_time/link_with_tooltip"
        }}>{`Rails 'Link with tooltip' preview`}</a>{` for implementation examples.`}</li>
      <li parentName="ul">{`For static elements, consider using the full date/time by default and hiding the `}<inlineCode parentName="li">{`title`}</inlineCode>{` attribute.`}</li>
    </ul>
    <h3>{`How to test the component`}</h3>
    <h4>{`Integration tests`}</h4>
    <ul>
      <li parentName="ul">{`Whenever the shorter relative time is presented to the user, there is a way for users to see the precise localized date/time as well. This method must work for all users – including keyboard and screen reader users.`}</li>
    </ul>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="RelativeTime" mdxType="AccessibilityLink" />

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>{`Relative time data is derived from the `}<a parentName="li" {...{
            "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/RelativeTimeFormat"
          }}>{`Intl.RelativeTimeFormat`}</a>{` API.`}<a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-2"
        }}>{`Date time data is derived from the `}<a parentName="li" {...{
            "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/RelativeTimeFormat"
          }}>{`Intl.DateTimeFormat`}</a>{` API.`}<a parentName="li" {...{
            "href": "#fnref-2",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-3"
        }}>{`The long format output depends on the user agent's timezone. For example, it will be `}<inlineCode parentName="li">{`on MMM D, YYYY`}</inlineCode>{` for US English that uses month-day-year order, `}<inlineCode parentName="li">{`on D MMM, YYYY`}</inlineCode>{` for British English that uses day-month-year order or even `}<inlineCode parentName="li">{`on YYYY MMM D`}</inlineCode>{` for Korean that uses year-month-day order.`}<a parentName="li" {...{
            "href": "#fnref-3",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      